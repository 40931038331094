<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4">
        Kiosk Billing Management
        <v-spacer></v-spacer>
      </v-card-title>
    </v-card>

    <!-- <v-card elevation="0" class="px-3 mt-3">
      <v-data-table
        :loading="loading"
        :headers="getHeaders"
        :items="userRole"
        :search="search"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.userCount="{ item }">
          {{ item.userCount }}
        </template>
        <template v-slot:item.dateCreated="{ item }">
          {{ item.dateCreated | formatDate }}
        </template>
        <template v-slot:item.lastUpdated="{ item }">
          {{ item.lastUpdated | formatDate }}
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description | trimLength }}
        </template>
        <template v-slot:item.actions="{ item }">
          <payment :roleIndex="item"></payment>
        </template>
      </v-data-table>
    </v-card> -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import Payment from "./payment.vue";

export default {
  name: "billing",
  components: {
    Payment,
  },
  data() {
    return {
      loading: true,
      search: "",
      headers: [
        { text: "Role Name", align: "start", value: "name" },
        { text: "Description", value: "description" },
        { text: "Users", value: "userCount" },
        { text: "Last Updated", value: "lastUpdated" },
        { text: "Date Created", value: "dateCreated" },
      ],
      superAdminHeaders: [
        { text: "Role Name", align: "start", value: "name" },
        { text: "Description", value: "description" },
        { text: "Users", value: "userCount" },
        { text: "Last Updated", value: "lastUpdated" },
        { text: "Date Created", value: "dateCreated" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["userRoles", "isSuperAdmin"]),
    getHeaders() {
      if (this.isSuperAdmin) {
        return this.superAdminHeaders;
      } else {
        return this.headers;
      }
    },
    userRole() {
      let role = [];
      this.userRoles.forEach((user) => {
        console.log("");
        let data = user.data;
        data["id"] = user.id;
        role.push(data);
      });
      return role;
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }

      return `${val.substring(0, 50)}...`;
    },
  },
  mounted() {
    this.$store.dispatch("getUserRoles").then(
      () => {
        this.loading = false;
      },
      (error) => {
        this.$store.dispatch("showSnackBar", {
          text: error,
          color: "error",
        });
      }
    );
  },
};
</script>